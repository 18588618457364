<template>
  <div>
    <v-text-field
      ref="inputField"
      v-bind="$attrs"
      :label="$translation.t('Website URL')"
      outlined
      v-model="model"
      :rules="[rules.required, rules.url]"
      hide-details="auto"
      :readonly="disabled"
      :error="!!error"
      :error-messages="error"
      :class="{ 'input-disabled': disabled }"
    >
    </v-text-field>
  </div>
</template>

<script>
import rules from "@/utils/inputRules";
import projectInputFields from "@/utils/mixins/projectInputFields";

export default {
  name: "WebsiteUrlField",
  props: {
    value: String,
    error: String,
    global: {
      type: Boolean,
      default: true,
    },
  },
  mixins: [projectInputFields],
  data: () => ({
    rules,
    model: "",
  }),
  created() {
    if (this.global) this.model = this.url ?? "";
  },
  mounted() {
    this.model = this.value || this.model;
  },
  computed: {
    url() {
      return this.projectInformationValue("website_url");
    },
  },
  watch: {
    model(newUrl) {
      this.$emit("input", newUrl);
    },
    value(newUrl) {
      this.model = newUrl;
    },
    url(newUrl) {
      if (this.global) this.model = newUrl;
    },
  },
};
</script>
